// A copy of this also exists in inherits_from.coffee
// That's used on admin & old order form.
// Once we get rid of old order form, we should be able to get rid of that coffee file, make the admin version use this. Things to note when we do that:
//  1. Some things trigger simplero:inherit using the jquery event system. On admin, afaik we might be loading 2 jqueries. If things are being triggered from 1 and listened on another, that might not work.
//  2. The function here has changed it's signature. Instead of adding to $.fn.xx and using $.each, it's now a normal JS function. You need to account for this when calling it from onDomChanged.
import $ from 'jquery'

export function inheritsFrom(source, destination, options) {
  if (!options) {
    options = {}
  }
  const $destination = $(destination)
  const $source = $(source)
  let changed_manually = false
  let val_on_focus = null

  const getSourceVal = options.getSourceVal || defaultGetSourceVal

  const setDestVal = function (val) {
    $destination.val(val)
    val_on_focus = val
    if (options && !options.notify) {
      $destination.change()
    }
  }

  if (
    getSourceVal($source) !== $destination.val().trim() &&
    $destination.is(':visible')
  ) {
    changed_manually = true
  }

  $source.on('animationstart', (event) => {
    const animationName = event.originalEvent.animationName

    if (
      animationName == 'onAutoFillStart' ||
      animationName == 'onAutoFillCancel'
    ) {
      $source.trigger('change')
    }
  })

  $source.on(
    `simplero:inherit ${!options.transform ? 'change keyup' : 'blur'}`,
    function () {
      if (changed_manually) return

      const val = getSourceVal($source)
      if (!options.transform) {
        return setDestVal(val)
      }

      $.ajax({
        url: options.transform,
        data: { s: val },
        success: (data) => setDestVal(data.output),
        error: () => setDestVal(val),
      })
    }
  )

  $destination.focus(() => (val_on_focus = $destination.val()))

  $destination.blur(function () {
    const cur_val = $destination.val()
    if (cur_val === '') {
      changed_manually = false
    } else if (cur_val !== val_on_focus) {
      changed_manually = true
    }
  })
}

// You can override this by passing options for custom behavior to derive the source value from
const defaultGetSourceVal = ($source) => {
  return $source
    .map((index, elem) => $(elem).val())
    .get()
    .join(' ')
    .trim()
}
